import React, { useState } from "react";
import { CSSTransitionGroup } from "react-transition-group";
import { Header } from "../components/Header";
import { Panel } from "../components/Panel/Panel";
import { Container } from "../components/Panel/Container";
import { Cell } from "../components/Panel/Cell";
import { StorePassword } from "../components/Form/StorePassword";
import { ShareToken } from "../components/Form/ShareToken";
import { Wallpaper } from "../components/Wallpaper/Wallpaper";
import { BackButton } from "../components/BackButton";

export interface SecretProps {
  initialStep?: number;
}

export const Secret: React.FC<SecretProps> = ({
  initialStep = 1,
}): JSX.Element => {
  const [step, setStep] = useState<number>(initialStep);
  const [token, setToken] = useState<string>("");

  return (
    <div className="page page--secret">
      <Header />
      <Panel active={true} classes={["home"]}>
        <Container>
          <Cell size="one-third component__wrapper has-background-white">
            <div className="component__store-send">
              <CSSTransitionGroup
                transitionName="slide"
                transitionEnterTimeout={900}
                transitionLeaveTimeout={900}
              >
                {step === 1 ? (
                  <StorePassword
                    setToken={setToken}
                    setStep={setStep}
                    buttonLabel="Share your secret!"
                  >
                    <h1 className="is-size-5">
                      Don't worry, your secret is safe with us{" "}
                      <span role="img" aria-label="Padlock">
                        🔒
                      </span>
                      .
                    </h1>
                    <p>
                      Enter your secret below and hit share to create your
                      one-time-use link. You can share this via email or by
                      copy/pasting the link.
                    </p>
                  </StorePassword>
                ) : (
                  ""
                )}
                {step === 2 ? (
                  <ShareToken token={token}>
                    <BackButton step={step} setStep={setStep} />
                  </ShareToken>
                ) : (
                  ""
                )}
              </CSSTransitionGroup>
            </div>
          </Cell>
        </Container>
      </Panel>
      <Wallpaper />
    </div>
  );
};
