import React from "react";

export interface TextareaProps {
  id?: string;
  rows?: number;
  value?: string;
  classes?: string[];
  readOnly?: boolean;
  required?: boolean;
  autoComplete?: string;
  placeholder?: string;
  onChange?: (event: any, ...args: any) => void;
  onFocus?: (event: any, ...args: any) => void;
}

export const Textarea: React.FC<TextareaProps> = ({
  value,
  rows = 6,
  classes = ["input"],
  ...props
}): JSX.Element => {
  return (
    <textarea
      className={[...classes].join(" ")}
      rows={rows}
      value={value}
      {...props}
    />
  );
};
