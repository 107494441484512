import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase/app";
import "firebase/analytics";

firebase.initializeApp({
  apiKey: "AIzaSyAxqgmSw-v71iskGRBPa0ABwfe92BhCN0M",
  authDomain: "single-use-io.firebaseapp.com",
  databaseURL: "https://single-use-io.firebaseio.com",
  projectId: "single-use-io",
  storageBucket: "single-use-io.appspot.com",
  messagingSenderId: "207789354694",
  appId: "1:207789354694:web:ed79c49eafeac4113eb1c7",
  measurementId: "G-46M097KVJ5",
});
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
