import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../components/Header";
import { Cell } from "../components/Panel/Cell";
import { Container } from "../components/Panel/Container";
import { Section } from "../components/Panel/Section";
import { SignUpForm } from "../components/Form/SignUp";

export interface HomeProps {}

export const Home: React.FC = (): JSX.Element => {
  return (
    <div className="page page--home">
      <Header />
      <Section classes={["panel__section--top"]}>
        <Container>
          <Cell classes={["half"]}>
            <h1 className="is-size-4">
              A{" "}
              <span role="img" aria-label="fire">
                🔥
              </span>{" "}
              way to send your passwords securely
            </h1>
            <p>
              Securely send your secrets using our easy to use web service.
              Simply add your secret and share via email or copy your
              one-time-use link. Your secret is encrypted using AES encryption
              and expires after one use. Secrets automatically expire after 7
              days and are erased from existence.
            </p>
            <div className="call-to-action">
              <Link to="/secret" className="button is-primary is-large">
                <span>Start here</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-right-circle"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <polyline points="12 16 16 12 12 8"></polyline>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>
              </Link>
              <a
                href="#GoPro"
                className="button is-large is-dark go-pro-anchor"
              >
                <span>Why not go pro</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-down-circle"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <polyline points="8 12 12 16 16 12"></polyline>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                </svg>
              </a>
            </div>
          </Cell>
        </Container>
      </Section>
      <Section classes={["panel__section--middle"]} id="GoPro">
        <Container>
          <Cell classes={["half"]}>
            <h2 className="is-size-4">Why Go Pro.</h2>
            <p>
              If your day to day requires you to send and receive sensitive
              credentials, then a singleuse.io pro membership could be ideal for
              your brand.
            </p>
            <p>
              <strong>Pro features:</strong>
            </p>
            <ul className="features">
              <li>
                <strong>Exclusive singleuse.io subdomain</strong>
                <br />
                (e.g. yourbrand.singleuse.io) Increase client trust with your
                personal URL.
              </li>
              <li>
                <strong>Branded for you</strong>
                <br />
                Add your logo and custom background for your own personal look
                and feel.
              </li>
              <li>
                <strong>Pre-populated recipient(s)</strong>
                <br />
                Send your link to clients when requesting credentials and we
                will pre-populate your details, so there is no messing about.
              </li>
              <li>
                <strong>API access and integrations</strong>
                <br />
                Use our API to integrate singleuse.io with your web service or
                install our slack bot and create links directly from slack.
              </li>
              <li>
                <strong>And more to come...</strong>
                <br />
                we love feedback so if you have something in mind that would
                make our service even better please do let us know.
              </li>
            </ul>
            <p className="signup has-text-centered">
              <a href="#SignUp" className="has-text-white">
                <u>
                  Sign up below to be notified when we launch!{" "}
                  <span role="img" aria-label="rocket">
                    🚀
                  </span>
                </u>
              </a>
            </p>
          </Cell>
        </Container>
      </Section>
      <Section classes={["panel__section--about"]}>
        <Container>
          <Cell classes={["half"]}>
            <h2 className="is-size-4">About singleuse.io</h2>
            <p>
              We wanted to create a secure tool to send credentials and secrets
              based on the need in our own day to day jobs. There are plenty of
              services which allow you to securely send a secret whether that be
              a credential or note of some kind, but none of them quite hit the
              spot.
            </p>
            <p>
              Initially, we started by creating the singleuse.io as a secure
              means to deliver one time secrets with beautiful user experience.
              However, coming from a marketing background,{" "}
              <a href="#GoPro" className="has-text-white">
                <u>we felt more could be done.</u>
              </a>
            </p>
            <p>
              When sending secret phrases/credentials to our clients, we wanted
              them to know it had come from us. An exclusive domain and custom
              branded service to match seemed like the perfect solution. Pair
              this with the ability to simply send our personal link to the
              client and have the secrets come directly to us without the client
              entering a thing. It's magic!
            </p>
          </Cell>
        </Container>
      </Section>
      <Section classes={["panel__section--form"]} id="SignUp">
        <Container>
          <Cell classes={["half"]}>
            <h2 className="is-size-4">
              We want everything to be perfect for you.
            </h2>
            <p>
              We are still perfecting our pro features but drop your email
              below, and you will be the first to know when they are ready.
            </p>
            <SignUpForm />
            <p className="has-text-centered has-text-dark">
              <small>
                Don't worry we don't send spam and you can unsubscribe at any
                time.
              </small>
            </p>
          </Cell>
        </Container>
      </Section>
      <Section classes={["panel__section--footer"]}>
        <Container>
          <Cell classes={["half"]}>
            <p className="has-text-centered has-text-dark">
              Get in touch with us at{" "}
              <a href="mailto:hello@zakclayton.com">hello@zakclayton.com</a> or
              on{" "}
              <a
                href="https://twitter.com/ZakClayton2"
                target="_blank"
                rel="noopener noreferrer"
              >
                twitter
              </a>
            </p>
            <p className="has-text-centered has-text-dark">
              <small>&copy; singleuse.io 2020</small>
            </p>
          </Cell>
        </Container>
      </Section>
    </div>
  );
};
