import React from "react";
import { Button } from "./Form/Fields/Button";

export interface BackProps {
  step: number;
  setStep(step: number): void;
}

export const BackButton: React.FC<BackProps> = ({
  step,
  setStep,
}): JSX.Element => {
  const handleOnClick = (): void => {
    if (step > 1) {
      setStep(1);
    }
  };
  return (
    <Button
      label="Start again"
      classes={["back-button"]}
      onClick={() => handleOnClick()}
    />
  );
};
