import React from 'react'

export interface ContainerProps {
  classes?: string[]
}

export const Container: React.FC<ContainerProps> = ({classes = [], children }): JSX.Element => {
  return (
    <div className={['panel__container', ...classes].join(' ')}>
      {children}
    </div>
  )
}
