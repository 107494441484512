import React from 'react'

export interface CellProps {
  size?: string,
  classes?: string[]
}

export const Cell: React.FC<CellProps> = ({ size = '', classes = [], children }): JSX.Element => {
  return (
    <div className={['panel__cell', size, ...classes].join(' ')}>
      {children}
    </div>
  )
}
