import React from "react";
import { Textarea } from "./Textarea";
import { Button } from "./Button";

export interface PasswordProps {
  password?: string;
  onChange?: (event: any) => void;
  generatePassword: Function;
  classes?: string[];
}

export const Password: React.FC<PasswordProps> = ({
  password,
  onChange,
  classes = [],
  generatePassword,
}): JSX.Element => {
  return (
    <div className={["field", "field__password", ...classes].join(" ")}>
      <Textarea
        value={password}
        onChange={onChange}
        autoComplete="new-secret"
        classes={["field__password-input input--no-border input--no-bg"]}
      />
      <Button
        label="Generate random password"
        onClick={() => generatePassword()}
        classes={["field__generate-password"]}
        title="Generate password"
      />
    </div>
  );
};
