import React from 'react'

export interface PanelProps {
  active?: boolean,
  classes?: string[]
}

export const Panel: React.FC<PanelProps> = ({active = false, classes = [], children }): JSX.Element => {
  return (
    <div className={['panel__section', active ? 'active' : '', ...classes].join(' ')}>
      {children}
    </div>
  )
}
