import React from "react";
import { Copy } from "./Fields/Copy";

export interface CopyTokenProps {
  token: string;
  classes?: string[];
}

const HOST = window.location.protocol + "//" + window.location.host;

export const CopyToken: React.FC<CopyTokenProps> = ({
  token,
  classes = [],
  children,
}) => {
  const shareUrl: string = HOST + "/secret/" + token;
  return (
    <div className={["form__copy-token", ...classes].join(" ")}>
      {children}
      <Copy value={shareUrl} />
    </div>
  );
};
