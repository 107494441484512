import React, { useState } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { randomPassword } from "secure-random-password";
import { Password } from "./Fields/Password";
import { Button } from "./Fields/Button";
import { Loading } from "../Loading";
import { SecretResponse } from "../../types";
import { ErrorBanner, ErrorObject } from "../ErrorBanner";

const ENDPOINT: string =
  "https://europe-west2-single-use-io.cloudfunctions.net/generateToken";

const initialErrorState = {
  error: false,
};

export interface StorePasswordProps {
  classes?: string[];
  setStep(step: number): void;
  setToken(token: string): void;
  buttonLabel?: string;
}

export const StorePassword: React.FC<StorePasswordProps> = ({
  classes = [],
  setToken,
  setStep,
  buttonLabel = "Share!",
  children,
}): JSX.Element => {
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorObject>(initialErrorState);

  const handlePasswordInput = (event: any): void => {
    if (error.error === true) {
      setError({ error: false });
    }
    setPassword(event.target.value);
  };

  const handleSubmit = (): void => {
    if (password) {
      setError({ error: false });
      setLoading(true);
      axios({
        method: "POST",
        url: ENDPOINT,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          string: password,
        },
      })
        .then((res: AxiosResponse<SecretResponse>) => {
          if (res.data.shareable === undefined) {
            setError(res.data);
          } else {
            setToken(res.data.shareable);
            setStep(2);
          }
          setLoading(false);
        })
        .catch((err: AxiosError) => {
          if (err.response) {
            setError(err.response.data);
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
      setError({
        error: true,
        message: "Please enter a secret to continue",
      });
    }
  };

  const generatePassword = (): void => {
    setPassword(() => randomPassword());
  };

  return (
    <form className={["form__store-password", ...classes].join(" ")}>
      {children}

      <Password
        password={password}
        onChange={handlePasswordInput}
        generatePassword={generatePassword}
        classes={["form__password-field"]}
      />
      <ErrorBanner {...error}>
        <Loading isLoading={loading}>
          <Button
            label={buttonLabel}
            onClick={() => handleSubmit()}
            classes={["button", "is-primary", "form__store-button"]}
          />
        </Loading>
      </ErrorBanner>
    </form>
  );
};
