import React, { useState } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Button } from "./Fields/Button";
import { Input } from "./Fields/Input";
import { Textarea } from "./Fields/Textarea";
import { ErrorBanner, ErrorObject } from "../ErrorBanner";
import { Loading } from "../Loading";
import { CopyToken } from "./CopyToken";

const SHARE_ENDPOINT: string =
  "https://europe-west2-single-use-io.cloudfunctions.net/sendEmailWithLink";

export interface ShareTokenProps {
  token: string;
  classes?: string[];
}

interface SuccessObject {
  success: boolean;
  message?: string;
}

const regex: RegExp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

export const ShareToken: React.FC<ShareTokenProps> = ({
  token,
  classes = [],
  children,
}) => {
  const [from, setFrom] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorObject>({ error: false });
  const [response, setResponse] = useState<SuccessObject>({ success: false });

  const handleOnChange = (event: any, callback: React.SetStateAction<any>) => {
    callback(event.target.value);
  };

  const handleOnClick = (): void => {
    if (!regex.test(email)) {
      setError({
        error: true,
        message: "Please provide a valid email address.",
      });
      return;
    }

    setLoading(true);
    setError({ error: false });
    axios({
      method: "POST",
      url: SHARE_ENDPOINT,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        from,
        email,
        token,
        message,
      },
    })
      .then((res: AxiosResponse) => {
        setLoading(false);
        if (res.data.error === false) {
          setResponse({
            success: true,
            message: res.data.message,
          });
        } else {
          setError(res.data);
        }
      })
      .catch((err: AxiosError) => {
        // Need to handle errors
        if (err.response) {
          setError(err.response.data);
        }
        setLoading(false);
      });
  };

  return (
    <div className={["form__share-token", ...classes].join(" ")}>
      {children}

      <CopyToken token={token}>
        <p>Copy your one-time-use URL and share on your favourite platform.</p>
      </CopyToken>

      <p>Use the form below to email your secret.</p>

      <ErrorBanner {...error} />
      {response.success ? (
        <div className="success__banner">
          <p>{response.message}</p>
        </div>
      ) : (
        <>
          <div className="form__field">
            <label>
              Your name:
              <Input
                type="text"
                value={from}
                autoComplete="name"
                onChange={(e) => handleOnChange(e, setFrom)}
              />
            </label>
          </div>
          <div className="form__field">
            <label>
              Message:
              <Textarea
                value={message}
                onChange={(e) => handleOnChange(e, setMessage)}
              />
            </label>
          </div>
          <div className="form__field">
            <label>
              Recipients email:
              <Input
                type="email"
                value={email}
                onChange={(e) => handleOnChange(e, setEmail)}
                required={true}
                classes={["input"]}
                placeholder="hello@example.com"
                autoComplete="recipients-email-address"
              />
            </label>
          </div>
          <div className="form__field">
            <Loading isLoading={loading}>
              <Button
                label="Send secret via email"
                onClick={handleOnClick}
                classes={["button", "is-primary"]}
              />
            </Loading>
          </div>
        </>
      )}
    </div>
  );
};
