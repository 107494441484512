import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Secret } from "./pages/Secret";
import { Show } from "./pages/Reveal";
import "./assets/styles/App.scss";
import { Home } from "./pages/Home";

const App: React.FC = (): JSX.Element => {
  return (
    <Router>
      <div className="app">
        <Switch>
          <Route exact path="/secret">
            <Secret />
          </Route>
          <Route exact path="/secret/:token">
            <Show />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
