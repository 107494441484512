import React from "react";

export interface ErrorObject {
  error: boolean;
  message?: string;
}

export const ErrorBanner: React.FC<ErrorObject> = ({
  error = false,
  message = "",
  children,
}): JSX.Element => {
  return error ? (
    <div className="error__banner">
      <p>{message}</p>
    </div>
  ) : (
    <>{children}</>
  );
};
