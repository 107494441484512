import React, { useEffect, useState } from "react";

export interface WallpaperProps {}

export const Wallpaper: React.FC = (): JSX.Element => {
  const [wallpaper, setWallpaper] = useState<string | undefined>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const onImageLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    const image = new window.Image();
    image.onload = onImageLoad;
    image.src = "https://source.unsplash.com/1000x1000/?iceland";
    setWallpaper(image.src);
  }, []);

  return (
    <div className="wallpaper">
      <div
        style={{
          backgroundImage: `url(${wallpaper})`,
        }}
        className={[
          "wallpaper__image",
          loaded ? "wallpaper__image--loaded" : "",
        ].join(" ")}
      ></div>
      <div className="wallpaper__heading">
        <p>Always sending secrets to clients?</p>
        <p>
          Get singleuse.io for your brand including personal sub domain and
          branded page
        </p>
        <a href="/#SignUp" className="button is-primary">
          Be notified on launch!
        </a>
      </div>
    </div>
  );
};
