import React, { useEffect } from "react";

export interface LoadingProps {
  isLoading: boolean;
}

export const Loading: React.FC<LoadingProps> = ({
  isLoading,
  children,
}): JSX.Element => {
  useEffect(() => {
    // Need to add a delay to loading...
  }, [isLoading]);

  return isLoading ? <div className="loading__button"></div> : <>{children}</>;
};
