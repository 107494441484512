import React, { useState } from "react";
import axios, { AxiosResponse } from "axios";
import { Email } from "./Fields/Email";
import { ErrorObject } from "../ErrorBanner";
import { Loading } from "../Loading";

const SIGN_UP_ENDPOINT =
  "https://script.google.com/macros/s/AKfycbxjMW9nieUSdSwlJt2po97RSNfdYkM1joLI66hMvXX_O_G3D-vc/exec";

interface Validator<T> {
  (arg: T): boolean;
}

export const SignUpForm: React.FC = (): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<ErrorObject>({ error: false });
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError({ error: false });
  };

  const validateEmail: Validator<string> = (email) => {
    const regex: RegExp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return regex.test(email);
  };

  const onSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (!email || !validateEmail(email)) {
      setError({
        error: true,
        message: "Please provide a valid email.",
      });
      return;
    }
    setLoading(true);
    axios
      .get(SIGN_UP_ENDPOINT, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          email,
        },
      })
      .then((res: AxiosResponse<{ result: string; row: number }>) => {
        setLoading(false);
        if (res.data?.result === "success") {
          setSuccess(true);
          setEmail("");
        } else {
          setSuccess(false);
          setError({
            error: true,
            message: "Sorry, there has been an error.",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        setError({
          error: true,
          message: "Sorry, there has been an error.",
        });
      });
  };
  return (
    <form>
      <Loading isLoading={loading}>
        <Email
          email={email}
          handleOnChange={onChange}
          handleOnClick={onSubmit}
        />
      </Loading>
      {success && (
        <p className="has-text-success">
          Thank you for your support{" "}
          <span role="img" aria-label="Party Popper">
            🎉
          </span>
        </p>
      )}
      {error && error.error ? (
        <p className="has-text-danger">{error.message}</p>
      ) : (
        ""
      )}
    </form>
  );
};
