import React from "react";
import { Textarea } from "./Textarea";
import { Button } from "./Button";

export interface CopyProps {
  value: string;
}

export const Copy: React.FC<CopyProps> = ({ value }): JSX.Element => {
  const handleFocus = (event: any) => event.target.select();
  const handleCopy = () => {
    let input: HTMLElement | null = document.getElementById("CopyToken");
    input?.focus();
    document.execCommand("copy");
  };

  return (
    <div className="field field__token">
      <Textarea
        id="CopyToken"
        value={value}
        classes={[
          "input",
          "input--no-bg",
          "input--no-border",
          "field__token-input",
        ]}
        readOnly={true}
        onFocus={handleFocus}
      />
      <Button
        label="Copy"
        onClick={handleCopy}
        classes={["button", "is-primary", "field__copy"]}
      />
    </div>
  );
};
