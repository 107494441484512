import React from 'react';

export interface ButtonProps {
  id?: string,
  label?: string,
  onClick?: (event: any) => void,
  classes?: string[],
  title?: string,
}

export const Button: React.FC<ButtonProps> = ({label, classes = ['button'], ...props}): JSX.Element => {
  return (
    <button
      type="button"
      className={[...classes].join(' ')}
      {...props}
    >
      {label}
    </button>
  )
}
