import React from 'react'

export interface InputProps {
  id?: string, 
  type: 'text' | 'password' | 'email',
  value?: string,
  classes?: string[],
  readOnly?: boolean,
  required?: boolean,
  autoComplete?: string,
  placeholder?: string,
  onChange?: (event: any, ...args: any) => void;
  onFocus?: (event: any, ...args: any) => void;
}

export const Input: React.FC<InputProps> = ({type = 'text', value, classes = ['input'], ...props}): JSX.Element => {
  return (
    <input
      type={type}
      value={value}
      className={[...classes].join(' ')}
      {...props}
    />
  )
}
