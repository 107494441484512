import React from "react";
import { useParams } from "react-router-dom";
import { Reveal } from "../components/Reveal";
import { Header } from "../components/Header";
import { Wallpaper } from "../components/Wallpaper/Wallpaper";
import { Panel } from "../components/Panel/Panel";
import { Container } from "../components/Panel/Container";
import { Cell } from "../components/Panel/Cell";

export interface ShowProps {}

export interface Params {
  token: string;
}

const ENDPOINT: string =
  "https://europe-west2-single-use-io.cloudfunctions.net/returnDecryptedString";

export const Show: React.FC = (): JSX.Element => {
  const { token } = useParams<Params>();
  return (
    <div className="page--reveal reveal">
      <Header />
      <Panel active={true} classes={["home"]}>
        <Container>
          <Cell size="one-third component__wrapper has-background-white">
            <Reveal token={token} endpoint={ENDPOINT}>
              <p className="is-size-5">Hover below to reveal your secret.</p>
              <p>
                Please make sure you safely store your secret as you will not be
                able to return to this page. We recommend a password vault to
                keep all your secrets safe.
              </p>
            </Reveal>
          </Cell>
        </Container>
      </Panel>
      <Wallpaper />
    </div>
  );
};
