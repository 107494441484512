import React, { useState, useEffect, FC } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Copy } from "./Form/Fields/Copy";
import { Loading } from "./Loading";
import { SecretResponse } from "../types";
import { ErrorBanner, ErrorObject } from "./ErrorBanner";

export interface RevealProps {
  token: string;
  endpoint: string;
  initialMask?: string;
  initialReveal?: boolean;
}

const initialErrorState = {
  error: false,
  message: "",
};

export const Reveal: React.FC<RevealProps> = ({
  token,
  endpoint,
  initialMask = "",
  children,
}): JSX.Element => {
  const [mask, setMask] = useState<string>(initialMask);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<ErrorObject>(initialErrorState);
  const [reveal, setReveal] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "POST",
      url: endpoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token,
      },
    })
      .then((res: AxiosResponse<SecretResponse>) => {
        if (res.data.error) {
          setError(res.data);
        } else {
          if (res.data.secret) {
            setMask(res.data.secret);
          } else {
            setError(res.data);
          }
        }
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          setError(err.response.data);
        }
        setLoading(false);
      });
  }, [token, endpoint]);

  return (
    <div className="reveal__section">
      <ErrorBanner {...error} />
      <div className="reveal__intro">{children}</div>
      <Loading isLoading={loading}>
        {(!error || !error.error) && (
          <div
            className="reveal__field"
            onMouseEnter={() => setReveal(true)}
            onMouseLeave={() => setReveal(false)}
          >
            <div
              className={[
                "reveal__overlay",
                reveal ? "reveal__overlay--hide" : "",
              ].join(" ")}
            >
              <h2>HOVER&nbsp;TO&nbsp;REVEAL</h2>
            </div>
            <Copy value={mask} />
          </div>
        )}
      </Loading>
    </div>
  );
};
