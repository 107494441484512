import React from "react";

export interface HeaderProps {}

export const Header: React.FC = (): JSX.Element => {
  return (
    <header>
      <a href="/" className="brand">
        singleuse.io
      </a>
    </header>
  );
};
