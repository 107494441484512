import React from "react";
import { Input } from "./Input";
import { Button } from "./Button";

export interface EmailProps {
  email?: string;
  handleOnChange(event: any): void;
  handleOnClick(event: any): void;
}

export const Email: React.FC<EmailProps> = ({
  email,
  handleOnChange,
  handleOnClick,
}): JSX.Element => {
  return (
    <div className="field field__email">
      <Input
        type="email"
        value={email}
        onChange={handleOnChange}
        required={true}
        classes={[
          "input",
          "input--no-bg",
          "input--no-border",
          "field__email-input",
        ]}
        placeholder="hello@example.com"
      />
      <Button
        label="Notify me!"
        onClick={handleOnClick}
        classes={["button", "is-dark", "field__send"]}
      />
    </div>
  );
};
