import React from "react";

export interface SectionProps {
  classes?: string[];
  id?: string;
}

export const Section: React.FC<SectionProps> = ({
  classes = [],
  id,
  children,
}): JSX.Element => {
  return (
    <div className={["panel__section", ...classes].join(" ")} id={id}>
      {children}
    </div>
  );
};
